import axios from "../../utils/request";

//报警查询
export function getReplaceControlleListPage(data) {
  return axios({
    url: "/clodchainAM/web/get_replace_controller_list_page",
    method: "get",
    params: data
  });
}

export function exportData(data) {
  return axios({
    url: "/clodchainAM/web/batch_replace_controller_info",
    method: "get",
    params: data,
    responseType: "blob"
  });
}
